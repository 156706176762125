import { useEffect } from "react";
import { Banner } from "../components/banner";
import "../styles/aboutUs.css";
import { characteres, infoAboutUs } from "../data/aboutUsPageData";
import { Character } from "../components/character";

export const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const childrenBackground = (
    <img src={"assets/images/usImages/about-us.jpeg"} alt={"nosotras"} />
  );

  return (
    <div className="aboutuspage-container">
      <Banner childrenBackground={childrenBackground} title="Nosotras" />
      {infoAboutUs.map((item) => (
        <div className={`aboutus-content-slogan ${item.type}`} key={item.title}>
          <h2 className="aboutus-content-slogan-title">{item.title}</h2>
          <p className="aboutus-content-slogan-text">{item.text}</p>
        </div>
      ))}

      <div className="ourteam-container">
        <h2 className="ourteam-container">Nuestro equipo</h2>

        {characteres.map(({ grade, imageUrl, name, postgrade, text, email }) => (
          <Character
            email={email}
            grade={grade}
            imageUrl={imageUrl}
            name={name}
            postgrade={postgrade}
            text={text}
            key={name}
          />
        ))}
      </div>
    </div>
  );
};
