export const infoAboutUs = [
  {
    title: "Misión",
    text: "  Desarrollar labores de consultoría y asesoría en los campos de la ingeniería y gestión ambiental, siendo aliados estratégicos de nuestros clientes con el apoyo de profesionales idóneos y con vocación de servicio que los asesoren en la toma de decisiones y les permitan gestionar sus procesos bajo criterios de responsabilidad ambiental, social y económica.",
    type: "first",
  },
  {
    title: "Visión",
    text: "Ser reconocidos en el año 2030 como una empresa altamente competitiva a nivel nacional que brinda soluciones integrales a las necesidades de sus clientes en los campos de la ingeniería y gestión ambiental, contribuyendo al mejoramiento continuo de sus procesos y al cumplimiento de sus estándares de calidad y medio ambiente.",
    type: "second",
  },
];

export const characteres = [
  {
    imageUrl: "assets/images/usImages/paola.jpeg",
    name: "Paola A. Martínez Agudelo",
    email:"paola.martinez@mygingenieria.com",
    grade: "Ingeniera Ambiental",
    postgrade: "Especialista en Gerencia de Recursos Naturales",
    text: "Experiencia profesional de más de 17 años y sólidos conocimientos en la elaboración de Estudios Ambientales, desarrollo e implementación de Planes de Manejo Ambiental, seguimiento y auditoría ambiental de proyectos. Conocimientos en Economía Circular, tratamiento de aguas residuales y amplia experiencia en la asesoría para el desarrollo de monitoreos Ambientales de las matrices Agua, Aire, Ruido.",
  },
  {
    imageUrl: "assets/images/usImages/sandra.jpeg",
    name: "Sandra M. Forero Vargas",
    email:"sandra.forero@mygingenieria.com",
    grade: "Ingeniera Ambiental",
    postgrade: "Especialista en Gerencia de Recursos Naturales",
    text: "Experiencia profesional de más de 15 años en la planificación e implementación de Sistemas de mejora de desempeño ambiental en el sector público y privado, Gestión Posconsumo de Medicamentos Vencidos, manejo y control de Aguas residuales industriales, elaboración de Informes de cumplimiento ambiental y gestión de trámites de cumplimiento legal ante las autoridades ambientales competentes.",
  },
  {
    imageUrl: "assets/images/usImages/carolina.jpeg",
    name: "Carolina Gómez Poveda",
    email:"carolina.gomez@mygingenieria.com",
    grade: "Ingeniera Ambiental",
    postgrade: "Especialista en evaluación de proyectos ambientales",
    text: "Experiencia profesional de más de 12 años en coordinación de personal, liderazgo de proyectos ambientales, educación ambiental, tratamiento de aguas residuales domésticas y no domésticas, construcción de indicadores, apertura de acciones de mejoras y correctivas, manejo de matrices de riesgo, interlocución con entidades ambientales, empresas de servicio público y gestores de residuos, manejos de centros de acopio, elaboración de informes a entes de control y registro de información en ventanillas y plataformas de autoridades ambientales.",
  },
];
