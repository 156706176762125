export const aboutPages = [
  {
    id: "soprte-legal",
    bannerTitle: "Soporte legal",
    bannerText:
      "En M&G Ingeniería y Consultoría ofrecemos acompañamiento y soporte legal para garantizar el cumplimiento normativo en materia ambiental y sanitaria. Nos especializamos en la gestión de trámites, permisos y autorizaciones asegurando el cumplimiento de los requisitos legales. Contamos con la experiencia y disposición para responder a requerimientos normativos de carácter ambiental.",
    bannerImageUrl: "../assets/images/aboutImages/soporte-legal.jpg",
    title: "Algunos de nuestros servicios",
    contentImageUrl: "../assets/images/aboutImages/soporte-legal-content.jpg",
    contentText: [
      "Gestión de trámites, permisos y autorizaciones ambientales y sanitarias.",
      "Respuestas a requerimientos de autoridades ambientales y sanitarias proporcionando la documentación y la información necesaria de manera oportuna y precisa.",
      "Informes y reportes periódicos de cumplimiento para autoridades ambientales y sanitarias garantizando una comunicación fluida y eficaz.",
      "Acompañamiento en visitas de autoridades ambientales y sanitarias brindando asesoramiento en tiempo real y facilitando el proceso para nuestros clientes.",
      "Elaboración de estudios ambientales (Planes de manejo ambiental, estudios de impacto ambiental, entre otros), asegurando que nuestros clientes cumplan con los requisitos legales y contribuyan a la protección del medio ambiente.",
    ],
  },
  {
    id: "seguimiento-monitoreo",
    bannerTitle: "Seguimiento y monitoreo",
    bannerText:
      "Con nuestro enfoque integral de seguimiento y monitoreo, brindamos a nuestros clientes la tranquilidad de saber que están operando de manera responsable y en cumplimiento de la normatividad ambiental vigente. Prestamos los servicios de monitoreo de variables ambientales a través de alianzas estratégicas con laboratorios acreditados ante el IDEAM.",
    bannerImageUrl: "../assets/images/aboutImages/seguimiento-monitoreo.jpeg",
    title: "Algunos de nuestros servicios",
    contentImageUrl:
      "../assets/images/aboutImages/seguimiento-monitoreo-content.jpeg",
    contentText: [
      "Monitoreos calidad de aire y ruido donde evaluamos los niveles de contaminantes atmosféricos y acústicos para identificar posibles riesgos para la salud y el medio ambiente.",
      "Monitoreos de fuentes de emisión para identificar y controlar las emisiones de contaminantes en el aire, asegurando el cumplimiento de la normatividad ambiental.",
      "Análisis de suelos para evaluar la calidad y la contaminación del suelo, proporcionando información crucial para la gestión adecuada de suelos contaminados y la prevención de impactos negativos en la salud humana y el medio ambiente.",
      "Caracterización de residuos sólidos convencionales y peligrosos mediante la identificación y clasificación de estos, así como la recomendación de medidas para su manejo y disposición adecuados.",
      "Monitoreos de calidad de agua potable, residual, y de procesos industriales, para garantizar el cumplimiento de los estándares de calidad y prevenir la contaminación de cuerpos de agua y recursos hídricos.",
      "Modelación de contaminantes atmosféricos (ruido, aire, agua) que permitan predecir la contaminación ambiental en el medio y tomar las acciones preventivas, de mitigación y /o de control necesarias para lograr el mínimo impacto posible al entorno.",
    ],
  },
  {
    id: "adaptacion-mitigacion",
    bannerTitle: "Adaptación y mitigación",
    bannerText:
      "La adaptación y la mitigación son dos estrategias fundamentales en la lucha contra el cambio climático. En términos simples, la adaptación se refiere a ajustarse a los impactos ya inevitables del cambio climático, mientras que la mitigación implica tomar medidas para principalmente reducir y/o prevenir esos impactos. En M&G Ingeniería y Consultoría, entendemos la importancia de ambas estrategias y trabajamos con nuestros clientes para desarrollar soluciones integrales que aborden estas necesidades, permitiendo una transición hacia una economía baja en carbono y más amigable con el medio ambiente.",
    bannerImageUrl: "../assets/images/aboutImages/adaptacion-mitigacion.jpg",
    title: "Algunos de nuestros servicios",
    contentImageUrl: "../assets/images/aboutImages/adaptacion-mitigacion-content.jpg",
    contentText: [
      "Programas de ahorro y uso eficiente de agua.",
      "Proyectos para incrementar la eficiencia en el consumo de energía.",
      "Programas de siembra y reforestación de áreas.",
      "Programas para la reducción en la generación de residuos sólidos.",
      "Medición de huella de carbono."
    ],
  },
  {
    id: "mejora-continua",
    bannerTitle: "Mejora continua",
    bannerText: "La mejora continua en los procesos ambientales es un pilar fundamental para cualquier organización comprometida con la sostenibilidad. En M&G Ingeniería y Consultoría, ofrecemos servicios especializados para ayudar a las empresas a identificar áreas de mejora en sus prácticas ambientales, trabajando en estrecha colaboración con nuestros clientes para desarrollar estrategias personalizadas que promuevan la eficiencia ambiental y la responsabilidad corporativa.",
    bannerImageUrl: "../assets/images/aboutImages/mejora-continua.jpg",
    title: "Algunos de nuestros servicios",
    contentImageUrl: "../assets/images/aboutImages/mejora-continua-content.jpg",
    contentText: [
      "Implementación de prácticas para la reducción, reúso y/o reciclaje de residuos sólidos.",
      "Diseño de sistemas para el reúso y/o recirculación de aguas.",
      "Auditorias de cumplimiento legal ambiental.",
    ],
  },
  {
    id: "educacion-ambiental",
    bannerTitle: "Educación Ambiental",
    bannerText: "En M&G Ingeniería y Consultoría creemos que, al educar a las personas sobre la importancia de la conservación de los recursos naturales y la protección del medio ambiente, podemos generar acciones tangibles que contribuyan a la preservación de nuestro planeta para las generaciones futuras. Ya sea a través de capacitaciones en empresas, actividades comunitarias o iniciativas escolares, estamos comprometidos a promover una cultura de sostenibilidad que transforme la manera en que interactuamos con nuestro entorno.",
    bannerImageUrl: "../assets/images/aboutImages/educacion-ambiental.jpg",
    title: "Algunos de nuestros servicios",
    contentImageUrl:
      "../assets/images/aboutImages/educacion-ambiental-content.jpg",
    contentText: [
      "Nuestros programas de educación ambiental van más allá de la simple transmisión de conocimientos; se centran en inspirar un cambio de comportamiento significativo.",
      "Capacitación y formación ambiental personal operativo, administrativo y alta gerencia.",
      "Diseño e implementación de campañas ambientales (eficiencia energética, manejo adecuado de residuos sólidos, ahorro y uso eficiente de agua, entre otros).",
      "Proyectos ambientales escolares.",
    ],
  },
  {
    id: "medicion-analisis-cumplimiento-legal",
    bannerTitle: "Medición, seguimiento y análisis",
    bannerText: "A través de la medición, seguimiento y el análisis de datos, en M&G Ingeniería y Consultoría garantizamos la eficiencia y el cumplimiento de los estándares ambientales, permitiendo a nuestros clientes evaluar y mejorar su desempeño ambiental de manera continua.",
    bannerImageUrl:
      "../assets/images/aboutImages/medicion-analisis-cumplimiento-legal.jpg",
    title: "Algunos de nuestros servicios",
    contentImageUrl:
      "../assets/images/aboutImages/medicion-analisis-cumplimiento-legal-content.jpg",
    contentText: [
      "Diseño, análisis y seguimiento de indicadores ambientales.",
      "Programas de gestión integral de residuos sólidos que incluyen la identificación de fuentes de generación, la implementación de medidas de reducción, reutilización y reciclaje, y el monitoreo de la disposición final, con el objetivo de minimizar el impacto ambiental y promover la economía circular.",
      "Diseño, construcción y operación de sistemas de tratamiento de agua residual adaptados a las necesidades específicas de cada cliente y cumpliendo con los estándares de calidad ambiental.",
      "Informes gerenciales detallados que resumen los resultados de nuestras actividades de medición, seguimiento y análisis, ofreciendo a nuestros clientes una visión clara y concisa de su desempeño ambiental y facilitando la toma de decisiones informadas para la mejora continua.",
    ],
  },
];
