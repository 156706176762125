import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./cardIcon.css";

interface Props {
  icon: IconDefinition;
  text: string;
}

export const CardIcon = ({ icon, text }: Props) => {
  return (
    <div className="cardIcon-container">
      <FontAwesomeIcon className="cardIcon-icon" icon={icon} />
      <span className="cardIcon-text">{text}</span>
    </div>
  );
};
