import { NavLink } from "react-router-dom";
import { CardIcon } from "../cardIcon";
import "./menuIcons.css";
import { faGavel, faMagnifyingGlass, faEarthAmericas, faArrowsRotate, faPersonChalkboard, faChartLine} from "@fortawesome/free-solid-svg-icons";

const menuIcons = [
  {
    icon: faGavel,
    text: "Soporte legal",
    url:"about/soprte-legal"
  },
  {
    icon: faMagnifyingGlass,
    text: "Seguimiento y monitoreo",
    url:"about/seguimiento-monitoreo"
  },
  {
    icon: faEarthAmericas,
    text: "Adaptación y mitigación",
    url:"about/adaptacion-mitigacion"
  },
  {
    icon: faArrowsRotate,
    text: "Mejora continua",
    url:"about/mejora-continua"
  },
  {
    icon: faPersonChalkboard,
    text: "Educación ambiental",
    url:"about/educacion-ambiental"
  },
  {
    icon: faChartLine,
    text: "Medición, seguimiento y análisis",
    url:"about/medicion-analisis-cumplimiento-legal"
  },
];

export const MenuIcons = () => {
  return (
    <div className="menuicons-container">
      {menuIcons.map((cardIcon) => {
        return (
          <NavLink key={cardIcon.url}className="navbar-link" to={cardIcon.url}>
            <CardIcon
              icon={cardIcon.icon}
              text={cardIcon.text}
              key={cardIcon.text}
            />
          </NavLink>
        );
      })}
    </div>
  );
};
