export const REGEX_VALIDATIONS = {
	isEmail: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
	noNumbers: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
	noLetters: /^(?:\*{3}\d+|\d+)$/,
	noThreeSameNumbers: /(\d)\1{2}/,
	specialCharacters: /[`!@#$%^&*()_+\-=[\]{};':"\\|<>/?~]/g,
	forwardSlash: /\//g,
	accents: /[\u0300-\u036f]/g,
	noSpecialCharacters: /^[a-zA-Z0-9 #Ññ.,-]+$/,
	noEmpty: /.+/,
	onlyLetters: /^[a-zA-Z]+$/,
	nitValidation: /^[1-9][0-9]{0,10}$/,
	onlyNumbers: /^(\*{3}|[0-9])+$/,
	onlyNumbersNoStartZero: /^[1-9][0-9]*$/,
	cellphoneValidation: /^(3)+(\d+$)/,
	onlyLettersAndNumberswithSpace: /^[a-zA-Z0-9 .,]+$/,
	onlyLettersAndWhiteSpacesSpanishAccent:
		/^(?![\s-])[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]+$/,
	onlyNumbersNotRepeatedThreeTimes: /^(?!.*(\d)\1{2})[a-zA-Z0-9 .,]\d+$/,
	removeWordsParamBussinesDays: /\d+/,
	validationAddressStreet:
		/^(?![^a-zA-Z0-9])(?!.*([a-zA-Z0-9 ])\1\1\1)[a-zA-Z0-9 ]+$/,
	validationAddressNumbers:
		/^(?![^a-zA-Z0-9])(?!.*([a-zA-Z0-9])\1\1\1)[a-zA-Z0-9]+$/,
	validationAddressNeighborhood:
		/^(?![^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ])(?!.*([a-zA-Z0-9áéíóúÁÉÍÓÚüÜñÑ ])\1\1\1)[a-zA-Z0-9áéíóúÁÉÍÓÚüÜñÑ ]+$/,
	validationAddressReciever:
		/^(?![^a-zA-Z0-9])(?!.*([a-zA-Z0-9@-_. ])\1\1)[a-zA-Z0-9@-_. ]+$/,
	validationAddressStreetOther:
		/^(?![^a-zA-Z0-9])(?!.*([a-zA-ZñÑ0-9-_# ])\1\1\1)[a-zA-ZñÑ0-9-_# ]+$/,
	allNumbers: /\d+/g,
	allLetters: /[^\d+]/g
};
