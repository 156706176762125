import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import "./contactForm.css";
import { Button } from "../button";
import { REGEX_VALIDATIONS } from "../../utils/regex-validations";

// Define the types for the form data
type FormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

interface Props {
  isAbout?: boolean;
}

export const ContactForm = ({ isAbout = false }: Props) => {
  // Initialize the form using useForm from react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [responseData, setResponseData] = useState("");
  const [userName, setUserName] = useState("");

  // Define the onSubmit function
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const templateParams = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message,
    };

    templateParams.name && setUserName(templateParams.name);

    emailjs
      .send(
        "service_7s6fkmq",
        "template_jbfjjfe",
        templateParams,
        "nhNtBMuyAHivD1RvW"
      )
      .then(
        (response) => {
          setResponseData(response.text);
        },
        (err) => {
          console.error("FAILED...", err);
        }
      );
  };

  function onChange(value: any) {
    console.log("Captcha value:", value);
  }

  return (
    <div className="contact-form">
      {responseData !== "OK" ? (
        <>
          {isAbout ? (
            <p>Déjanos tus datos y en breve te contactarémos</p>
          ) : (
            <h2 className="contact-form-title">Contáctanos</h2>
          )}

          <form
            className={isAbout ? "form-content-about" : "form-content"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Button linkTo="/" text="Ir al inicio" />
            <div className={!isAbout ? "input-content" : "input-content-about"}>
              <label htmlFor="name">Nombre:</label>
              <input
                className="input-content-input"
                type="text"
                id="name"
                {...register("name", {
                  required: true,
                  minLength: 3,
                  pattern: REGEX_VALIDATIONS.onlyLettersAndWhiteSpacesSpanishAccent,
                })}
              />
              {errors.name?.type === "required" && (
                <span className="error-message-form">Campo obligatorio</span>
              )}
              {errors.name?.type === ("minLength" || "pattern") && (
                <span className="error-message-form">
                  Ingrese un nombre válido
                </span>
              )}
            </div>
            <div className={!isAbout ? "input-content" : "input-content-about"}>
              <label htmlFor="email">Email:</label>
              <input
                className="input-content-input"
                type="email"
                id="email"
                {...register("email", {
                  required: true,
                  pattern: REGEX_VALIDATIONS.isEmail,
                })}
              />
              {errors.email?.type === ("pattern") && (
                <span className="error-message-form">
                  Ingrese un email válido
                </span>
              )}
              {errors.email?.type === "required" && (
                <span className="error-message-form">Campo obligatorio</span>
              )}
            </div>
            <div className={!isAbout ? "input-content" : "input-content-about"}>
              <label htmlFor="phone">Teléfono:</label>
              <input
                className="input-content-input"
                type="tel"
                id="phone"
                {...register("phone", {
                  required: true,
                  maxLength: 10,
                  minLength: 7,
                  pattern: REGEX_VALIDATIONS.onlyNumbers,
                })}
              />
              {errors.phone?.type === "required" && (
                <span className="error-message-form">Campo obligatorio</span>
              )}
              {errors.phone?.type ===
                ("minLength" || "maxLength" || "pattern") && (
                <span className="error-message-form">
                  Ingrese un dato válido
                </span>
              )}
            </div>
            <div className={!isAbout ? "input-content" : "input-content-about"}>
              <label htmlFor="message">Mensaje:</label>
              <textarea
                style={{
                  height: isAbout ? "50px" : "150px",
                }}
                className={"input-content-textarea"}
                id="message"
                {...register("message", { required: true, minLength: 10 })}
              ></textarea>
              {errors.message?.type === "required" && (
                <span className="error-message-form">Campo obligatorio</span>
              )}
              {errors.message?.type === "minLength" && (
                <span className="error-message-form">
                  Ingrese un mesaje válido
                </span>
              )}
            </div>
            <button className="form-button" type="submit">
              Enviar
            </button>
            {/* <ReCAPTCHA
              sitekey="6LdFSvEpAAAAAMGu50ZPMHkvGZrZ03klmvafBVnx"
              onChange={onChange}
            /> */}
          </form>
        </>
      ) : (
        <div className="thanks-content">
          <p className="thanks-content-title">
            ¡Gracias por contactarnos {userName}!{" "}
          </p>
          <p className="thanks-content-text">
            Hemos recibido tu mensaje y nos pondremos en contacto contigo lo
            antes posible.
          </p>
          <Button linkTo="/" text="Ir al inicio" />
        </div>
      )}
    </div>
  );
};
