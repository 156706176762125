import "./character.css";

interface Props {
  imageUrl: string;
  name: string;
  email: string;
  grade: string;
  postgrade: string;
  text: string;
}

export const Character = ({grade,imageUrl,name,postgrade,text, email}:Props) => {
  return (
    <div className="character-container">
      <div className="character-img-container">
        <img src={imageUrl} alt={name} />
      </div>
      <div className="character-container-info">
        <h3 className="character-container-title">{name}</h3>
        <span className="character-container-email">{email}</span>
        <span className="character-container-grade">{grade}</span>
        <span className="character-container-postgrade">{postgrade}</span>
        <p className="character-container-text">{text}</p>
      </div>
    </div>
  );
};
