import React from "react";
import { NavLink } from "react-router-dom";
import "./button.css";

interface Props {
  linkTo: string;
  text: string;
}

export const Button = ({ linkTo, text }: Props) => {
  return (
    <NavLink to={linkTo} style={{textDecoration: 'none'}}>
      <p className="button-go-home">{text}</p>
    </NavLink>
  );
};
