import { useState } from "react";
import { NavLink } from "react-router-dom";
import './navbar.css'
import { NavLinks } from "./NavLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const logo = `assets/logos/magrincoLogoTrans.png`
  return ( 
<nav className="navbar">
      <div className="navbar-container">
        <NavLink className="navbar-img-container" to="/">
          <img className="navbar-img" src={logo} alt="Magrinco Logo" />
        </NavLink>
        <NavLinks isOpen={isOpen} toggleMenu={toggleMenu} />
      </div>
      <div className="icon-hamburger">
        <button className="navbar-toggler" onClick={toggleMenu}>
          <FontAwesomeIcon className='whatsappButton-container--icon' icon={faBars} />
        </button>
      </div>
    </nav>
  );
};
