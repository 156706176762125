import Slider from "react-slick";
import "./carousel.css";
import { DeviceType, useDeviceType } from "../../hooks/useDeviceType";

const ourClientsCarousel = [
  {
    img: "assets/images/carousel/harinera_del_valle.jpeg",
    name: "harinera del valle"
  },
  {
    img: "https://www.hv.com.co/wp-content/uploads/2018/02/Logo-Mama-ia.jpg",
    name: "Brownie mama-ia"
  },
  {
    img: "https://industriasantaclara.co/wp-content/uploads/2017/12/LOGO-ISC-ALTA.png",
    name: "Santa clara"
  },
  {
    img: "assets/images/carousel/fundacion_san_antonio.jpeg",
    name: "Fundacion San Antonio"
  },
  {
    img: "https://www.fortecem.co/wp-content/uploads/2021/04/logo.png",
    name: "Fortecem"
  },
  {
    img: "https://www.vidrioandino.com/sites/d8-vidrioandino.com/files/2023-06/Vidrio_And_Logo_V_RGB-02.png",
    name: "Vidrio Andino"
  },
  {
    img: "assets/images/carousel/compania_trabajos_urbanos.jpeg",
    name: "Compañia de trabajos urbanos"
  },
  {
    img: "assets/images/carousel/IDEAM.jpeg",
    name: "IDEAM"
  },
];

const Carousel = () => {


const widthScreen = useDeviceType();

function getSlidesToShow(widthScreen: DeviceType) {
  switch(widthScreen) {
      case 'mobile':
          return 2;
      case 'desktop':
          return 4;
      case 'tablet':
          return 3;
      default:
          return 0; // Valor por defecto si widthScreen no coincide con ninguna opción
  }
}

const slidesToShow = getSlidesToShow(widthScreen);


  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
console.log(widthScreen, 'widthScreen')
  return (
    <Slider className="slider-container" {...settings}>
      {ourClientsCarousel.map((client, index) => (
        <div className="slider-container-img" key={index}>
          <img className="slider-img" src={client.img} alt={client.name} />
        </div>
      ))}
    </Slider>
  );
}

export default Carousel;
