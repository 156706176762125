import React, { useEffect } from "react";
import { ContactForm } from "../components/contactForm";
import { Button } from "../components/button";


export const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contactform-container">
      <ContactForm />
    </div>
  );
};
