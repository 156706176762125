import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import "./whatsappButton.css"

export const WhatsappButton = () => {
  return (
    <a className='whatsappButton-container' href="https://wa.me/3185954301/?text=Hola!%20Estoy%20interesado%20en%20más%20información%20de%20tu%20portafolio.%20Gracias" target="_blank">
       <FontAwesomeIcon className='whatsappButton-container--icon' icon={faWhatsapp} />
    </a>
  )
}
