import React, { ReactNode } from "react";
import ReactPlayer from "react-player/lazy";
import "./banner.css";
//import video from  "assets/videos/video.mp4"

interface Props {
  childrenBackground: ReactNode;
  title?: string;
  text?: string;
  childrenForm?: ReactNode
}

export const Banner = ({ childrenBackground, text, title, childrenForm }: Props) => {
  return (
    <div className="about-banner-container">
      {childrenBackground}
      <div className="about-banner-text-container">
        <h2 className="about-banner-title">{title}</h2>
        <p className="about-banner-content">{text}</p>
      </div>
      {childrenForm}
    </div>
  );
};
