import { Navigate, useParams } from "react-router-dom";
import { getPageById } from "../utils";
import "../styles/about.css";
import { ContactForm } from "../components/contactForm";
import { Banner } from "../components/banner";
import { NavLink } from "react-router-dom";
import { Button } from "../components/button";
import { useEffect } from "react";

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();

  const aboutPage = getPageById(id ?? "");
  if (!aboutPage) {
    return <Navigate to={"/404"} />;
  }

  const childrenBackground = (
    <img src={aboutPage.bannerImageUrl} alt={aboutPage.bannerTitle} />
  );

  return (
    <div className="about-container">
      <Banner
        childrenBackground={childrenBackground}
        title={aboutPage.bannerTitle}
        text={aboutPage.bannerText}
        // childrenForm = {formBanner}
      />
      <div className="about-content-container">
        <div className="about-content">
          <h1 className="about-content-title">{aboutPage.title}</h1>
          {aboutPage.contentText.map((content, index) => (
            <li className="about-content-text" key={index}>
              {content}
            </li>
          ))}
        </div>
        <div className="about-container-img">
          <img
            className="about-content-img"
            src={aboutPage.contentImageUrl}
            alt=""
          />
        </div>
      </div>
      <Button text="Inicio" linkTo="/" />
    </div>
  );
};
